<template>
  <div class="page-content page-content--flex">

    <TypeSelect
      v-if="activeBlock === 'type'"
      v-model="type"
      :type-list="typeList"
      :disable-button="!type"
      title="Я хочу предоставлять помощь"
      text="Выберите один из двух вариантов.<br/>Вы является физическим или юридическим лицом?"
      @next="activeBlock = 'location'"
    ></TypeSelect>

    <LocationSelect
      v-else
      v-model="location"
      @next="next"
      @back="activeBlock = 'type'"
    ></LocationSelect>

  </div>
</template>

<script>

import LocationSelect from "@component/Module/AssistanceSelect/LocationSelect";
import TypeSelect from "@component/Module/AssistanceSelect/TypeSelect";

export default {
  name: "AssistanceNeedSelect",
  components: {
    LocationSelect,
    TypeSelect
  },
  data() {
    return {
      type: null,
      location: {},
      activeBlock: 'type',
      typeList: [
        {
          name: 'Физическое лицо',
          value: 'individual',
          image: require('@img/icons/app-natural.svg')
        },
        {
          name: 'Юридическое лицо',
          value: 'entity',
          image: require('@img/icons/app-legal.svg')
        },
      ]
    };
  },
  methods: {
    next() {

      const routeName = this.type === 'individual'
        ? this.$routeName.ASSISTANCE_PROVIDE_FROM_PERSON
        : this.$routeName.ASSISTANCE_PROVIDE_FROM_COMPANY;

      this.$router.push({
        name: routeName,
        query: {
          ...this.location,
          type: this.type
        }
      });

    },
  },
};
</script>

<style lang="scss" scoped>

// block-fs
::v-deep {
  .block-fs {
    width: 100%;
    max-width: 500px;

    .block-fs__head {
      padding: 0 20px;
      margin-bottom: 24px;
      @include respond-to('sm') {
        text-align: center;
      }

      .page-text {
        max-width: 343px;
        margin: 0 auto;
      }
    }

    .block-fs__body {
      max-width: 343px;
      margin: 0 auto;
    }

    .block-fs__form {
      margin-bottom: 20px;
    }

    .btn {
      margin-bottom: 10px;
    }
  }
}

</style>
